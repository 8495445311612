import { t } from '@lingui/macro';
import { Button, Center, Stack, Text } from '@mantine/core';
import { useSessionStorage } from '@mantine/hooks';
import Spinner from 'ADMIN_COMMON/components/Spinner';
import { useObserver } from 'COMMON/reactive/useObserver';
import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { env } from '~/admin/common/constants/enviroments';
import { LOGGED_USER } from '../../admin/common/constants/localStorageKeys';
import { AuthStateName } from '../../auth/AuthAdapter';
import { Session } from '../core/Session';
import { permissions } from '../core/permissions';
import { SessionContext } from './SessionContext';

/**
 * @typedef {Object} Props
 * @property {React.ReactNode} children
 * @property {Object} auth
 * @property {import('../../admin/user/src/api/UserApi').UserApi} userApi
 */

/**
 * @type {React.FC<Props>}
 */
export const SessionProvider = ({ auth, userApi, children }) => {
    const authState = useObserver(auth.state);
    const isAuthenticated = authState.name === AuthStateName.Authenticated;

    const [cachedLoggedUser, setCachedLoggedUser] = useSessionStorage({
        key: LOGGED_USER,
        defaultValue: null,
        getInitialValueInEffect: false,
    });

    const loggedUserQuery = useQuery({
        queryKey: ['logged-user'],
        queryFn: ({ signal }) => userApi.fetchLoggedUser(signal),
        enabled: isAuthenticated,
        keepPreviousData: true,
        initialData: cachedLoggedUser,
    });

    useEffect(() => {
        if (!loggedUserQuery.data) {
            return;
        }

        if (isAuthenticated) {
            setCachedLoggedUser(loggedUserQuery.data);
        } else {
            setCachedLoggedUser(null);
        }
    }, [isAuthenticated, loggedUserQuery.data, setCachedLoggedUser]);

    const handleRetry = () => {
        loggedUserQuery.refetch().catch(() => null);
    };

    const handleLogout = () => {
        auth.signOut().catch(() => null);
    };

    const session = useMemo(() => {
        return new Session(loggedUserQuery.data, permissions, auth, env.env);
    }, [loggedUserQuery.data]);

    if (loggedUserQuery.isError) {
        return (
            <Center mih="100dvh" w="100%">
                <Stack align="center">
                    <Text>{t`Ошибка при получении данных пользователя`}</Text>

                    <Button
                        color="dark"
                        loading={loggedUserQuery.isFetching}
                        onClick={handleRetry}
                    >
                        {t`Попробовать еще раз`}
                    </Button>

                    <Button
                        variant="outline"
                        color="dark"
                        onClick={handleLogout}
                    >
                        {t`Выйти`}
                    </Button>
                </Stack>
            </Center>
        );
    }

    if (!loggedUserQuery.data) {
        return <Spinner />;
    }

    return (
        <SessionContext.Provider value={session}>
            {children}
        </SessionContext.Provider>
    );
};
