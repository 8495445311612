/**
 * @readonly
 * @enum {string}
 */
export const UserRole = {
    SuperAdmin: 'SUPER_ADMIN',
    Admin: 'ADMIN',
    FirstLine: 'first_line',
    SecondLine: 'second_line',
    Alarm: 'alarm',
    Operator: 'operator',
};
