const searchParams = new URLSearchParams(window.location.search);

const AUTHENTICATION_FLOW_TYPE = searchParams.get(
    'config:AUTHENTICATION_FLOW_TYPE'
);

export const env = {
    baseURL: process.env.API_URL,
    cognitoUrl: process.env.COGNITO_URL,
    userPoolId: process.env.USER_POOL_ID,
    userPoolWebClientId: process.env.USER_POOL_WEB_CLIENT_ID,
    region: process.env.REGION,
    authenticationFlowType:
        AUTHENTICATION_FLOW_TYPE || process.env.AUTHENTICATION_FLOW_TYPE,
    xAPIKey: process.env.X_API_KEY,
    SUPPORT_EMAIL: process.env.SUPPORT_EMAIL || 'sd@whoosh.bike',
    NEW_APP_URL: getNewAppUrl(process.env.NEW_APP_URL, window.location.origin),
    env: getEnv(window.origin),
};

function getNewAppUrl(url, origin) {
    // https://admin2.whoosh-cloud.com
    // https://mcc2.whoosh-cloud.com
    // https://admin2-stage.whoosh.bike
    // https://dev*.admin2-dev.whoosh.bike
    // https://admin2-stage*.dev.whoosh-cloud.com
    return url || origin.replace('admin', 'admin2').replace('mcc', 'mcc2');
}

function getEnv(origin) {
    if (origin.includes('local')) {
        return 'local';
    }

    if (origin.includes('dev') || origin.includes('stage')) {
        return 'dev';
    }

    return 'prod';
}
