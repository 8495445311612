import { t } from '@lingui/macro';
import DefaultModalInput from 'ADMIN_COMMON/components/DefaultModalInput';
import { Formik } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';

const batteriesChargeLevelEditSchema = yup.object({
    value: yup.object({
        maxLevel: yup
            .number()
            .required(t`Поле обязательно`)
            .integer(t`Должно быть целым числом`)
            .min(0, ({ min }) => t`Не меньше ${min}`)
            .max(50, ({ max }) => t`Не больше ${max}`),
    }),
});

export const BatteriesChargeLevelEditForm = ({
    formId,
    initialValues,
    onSubmit,
}) => (
    <Formik
        initialValues={initialValues}
        validationSchema={batteriesChargeLevelEditSchema}
        onSubmit={onSubmit}
    >
        {({
            handleSubmit,
            handleBlur,
            handleChange,
            values,
            touched,
            errors,
        }) => (
            <Form noValidate id={formId} onSubmit={handleSubmit}>
                <DefaultModalInput
                    id="value.maxLevel"
                    type="number"
                    min={0}
                    max={50}
                    required
                    invalidFeedback={errors.value?.maxLevel}
                    value={values.value?.maxLevel}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                        touched.value?.maxLevel && !!errors.value?.maxLevel
                    }
                >
                    {t`Верхняя планка заряда батареи`}
                    {':'}
                </DefaultModalInput>
            </Form>
        )}
    </Formik>
);
